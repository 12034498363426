<template>
  <div class="button-selector">
    <button
      v-for="option in options"
      :key="option.value"
      class="btn"
      :class="{
        'btn-outline': option.value !== selected,
      }"
      @click="handleSelected(option.value)"
    >
      <p>{{ option.label }}</p>
    </button>
  </div>
</template>

<script>
export default {
  name: "ButtonSelector",
  emits: ["select"],
  props: {
    options: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      selected: this.$props.options[0].value,
    };
  },
  methods: {
    handleSelected(value) {
      this.selected = value;
      this.$emit("select", value);
    },
  },
  created() {
    this.$emit("select", this.selected);
  },
};
</script>

<style scoped>
.button-selector {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}
.button-selector button {
  width: 155px;
  padding: 12px 5px;
  border: none;
  color: #fff;
  border-radius: 8px;
}
.btn-outline {
  background: #fff;
  color: #007afe !important;
  border: 1px solid #007afe !important;
}
</style>
