<template>
  <div class="card" :style="{ backgroundColor: getStatusColor(status) }">
    <div class="m-0">
      {{ getStatusText(status) }}
    </div>
  </div>
</template>

<script>
import { statusOptionsMixin } from "@/mixin/BorrowReturn/statusOptionsMixin";
export default {
  name: "BorrowReturnStatusCard",
  mixins: [statusOptionsMixin],
  props: {
    status: {
      type: String,
      default: "-",
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  background-color: #fff5c0;
  border-radius: 0.5em;
  width: fit-content;
  padding: 0.5em 1em;
  border: 1px solid #e0e2e7;
}
</style>
