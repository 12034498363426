export const BorrowReturnStatuses = Object.freeze({
  TO_PREPARE: "TO_PREPARE",
  PREPARED: "PREPARED",
  TO_SEND: "TO_SEND",
  USING: "USING",
  TO_RETURN: "TO_RETURN",
  RETURNED: "RETURNED",
  RETURNED_DAMAGED: "RETURNED_DAMAGED",
  OVERDUE: "OVERDUE",
  RETURNED_OVERDUE: "RETURNED_OVERDUE",
  RETURNED_DAMAGED_OVERDUE: "RETURNED_DAMAGED_OVERDUE",
  LOST: "LOST",
  NOT_AVAILABLE_PREPARE: "NOT_AVAILABLE_PREPARE",
  NOT_AVAILABLE_SEND: "NOT_AVAILABLE_SEND",
  CANCEL: "CANCEL",
  REVERSE: "REVERSE_TO_TO_SEND",
});
