import { BorrowReturnStatuses } from "@/constants/borrow-return/BorrowReturnStatuses";
import { mapGetters } from "vuex";

/***
 * @description This mixin is used to get the allowed options for each status
 */
export const statusOptionsMixin = {
  data() {
    return {
      allowedOptions: {
        [BorrowReturnStatuses.TO_PREPARE]: [
          {
            label: this.$t("borrow.prepared_status"),
            value: BorrowReturnStatuses.PREPARED,
          },
          {
            label: this.$t("borrow.cancelled_status"),
            value: BorrowReturnStatuses.CANCEL,
          },
        ],
        [BorrowReturnStatuses.PREPARED]: [
          {
            label: this.$t("borrow.need_prepare_status"),
            value: BorrowReturnStatuses.TO_PREPARE,
          },
          {
            label: this.$t("borrow.cancelled_status"),
            value: BorrowReturnStatuses.CANCEL,
          },
        ],
        [BorrowReturnStatuses.NOT_AVAILABLE_PREPARE]: [
          {
            label: this.$t("borrow.cancelled_status"),
            value: BorrowReturnStatuses.CANCEL,
          },
        ],
        [BorrowReturnStatuses.TO_SEND]: [
          {
            label: this.$t("borrow.sended_status"),
            value: BorrowReturnStatuses.USING,
          },
          {
            label: this.$t("borrow.cancelled_status"),
            value: BorrowReturnStatuses.CANCEL,
          },
        ],
        [BorrowReturnStatuses.USING]: [
          {
            label: this.$t("borrow.reverse_status"),
            value: BorrowReturnStatuses.REVERSE,
          },
          {
            label: this.$t("borrow.normal_returned_status"),
            value: BorrowReturnStatuses.RETURNED,
          },
          //TODO - เปิดเมื่อแจ้งซ่อมเสร็จแล้ว เฉพาะคนที่ซื้อ
          // {
          //   label: this.$t("borrow.broken_returned_status"),
          //   value: BorrowReturnStatuses.RETURNED_DAMAGED,
          // },
          {
            label: this.$t("borrow.lost_status"),
            value: BorrowReturnStatuses.LOST,
          },
        ],
        [BorrowReturnStatuses.TO_RETURN]: [
          {
            label: this.$t("borrow.normal_returned_status"),
            value: BorrowReturnStatuses.RETURNED,
          },
          //TODO - เปิดเมื่อแจ้งซ่อมเสร็จแล้ว เฉพาะคนที่ซื้อ
          // {
          //   label: this.$t("borrow.broken_returned_status"),
          //   value: BorrowReturnStatuses.RETURNED_DAMAGED,
          // },
          {
            label: this.$t("borrow.lost_status"),
            value: BorrowReturnStatuses.LOST,
          },
        ],
        [BorrowReturnStatuses.OVERDUE]: [
          {
            label: this.$t("borrow.returned_overdue_status"),
            value: BorrowReturnStatuses.RETURNED_OVERDUE,
          },
          //TODO - เปิดเมื่อแจ้งซ่อมเสร็จแล้ว เฉพาะคนที่ซื้อ
          // {
          //   label: this.$t("borrow.returned_overdue_broken_status"),
          //   value: BorrowReturnStatuses.RETURNED_DAMAGED_OVERDUE,
          // },
          {
            label: this.$t("borrow.lost_status"),
            value: BorrowReturnStatuses.LOST,
          },
        ],
      },
    };
  },
  computed: {
    ...mapGetters(["isBorrowReturnAdmin"]),
  },
  methods: {
    getStatusColor(status) {
      switch (status) {
        case BorrowReturnStatuses.TO_PREPARE:
          return "#FFF5C0";
        case BorrowReturnStatuses.TO_SEND:
          if (this.isBorrowReturnAdmin) {
            return "#FFF5C0";
          }
          return "#C0F0FF";
        case BorrowReturnStatuses.PREPARED:
          if (this.isBorrowReturnAdmin) {
            return "#C0F0FF";
          }
          return "#FFF5C0";
        case BorrowReturnStatuses.USING:
          return "#C0F0FF";
        case BorrowReturnStatuses.TO_RETURN:
          return "#FFE7D1";
        case BorrowReturnStatuses.RETURNED:
          return "#CDFFC0";
        case BorrowReturnStatuses.RETURNED_DAMAGED:
          return "#E0CACA";
        case BorrowReturnStatuses.OVERDUE:
          return "#FFC0C0";
        case BorrowReturnStatuses.RETURNED_OVERDUE:
          return "#FFE2C0";
        case BorrowReturnStatuses.RETURNED_DAMAGED_OVERDUE:
          return "#E0E2E7";
        case BorrowReturnStatuses.LOST:
          return "#E0CACA";
        case BorrowReturnStatuses.NOT_AVAILABLE_PREPARE:
        case BorrowReturnStatuses.NOT_AVAILABLE_SEND:
          return "#FFD1D1";
        case BorrowReturnStatuses.CANCEL:
          return "#E0E2E7";
        default:
          return null;
      }
    },
    getStatusText(status) {
      switch (status) {
        case BorrowReturnStatuses.TO_PREPARE:
          if (this.isBorrowReturnAdmin) {
            return this.$t("borrow.need_prepare_status");
          }
          return this.$t("borrow.preparing_status");
        case BorrowReturnStatuses.PREPARED:
          if (this.isBorrowReturnAdmin) {
            return this.$t("borrow.prepared_status");
          }
          return this.$t("borrow.preparing_status");
        case BorrowReturnStatuses.TO_SEND:
          if (this.isBorrowReturnAdmin) {
            return this.$t("borrow.need_send_status");
          }
          return this.$t("borrow.need_recieve_status");
        case BorrowReturnStatuses.USING:
          return this.$t("borrow.inuse_status");
        case BorrowReturnStatuses.TO_RETURN:
          return this.$t("borrow.need_return_status");
        case BorrowReturnStatuses.RETURNED:
          return this.$t("borrow.normal_returned_status");
        case BorrowReturnStatuses.RETURNED_DAMAGED:
          return this.$t("borrow.broken_returned_status");
        case BorrowReturnStatuses.CANCEL:
          return this.$t("borrow.cancelled_status");
        case BorrowReturnStatuses.OVERDUE:
          return this.$t("borrow.overdue_status");
        case BorrowReturnStatuses.RETURNED_OVERDUE:
          return this.$t("borrow.returned_overdue_status");
        case BorrowReturnStatuses.RETURNED_DAMAGED_OVERDUE:
          return this.$t("borrow.returned_overdue_broken_status");
        case BorrowReturnStatuses.NOT_AVAILABLE_PREPARE:
        case BorrowReturnStatuses.NOT_AVAILABLE_SEND:
          return this.$t("borrow.not_ready_status");
        case BorrowReturnStatuses.LOST:
          return this.$t("borrow.lost_status");
        default:
          return "-";
      }
    },

    /***
     * @param {BorrowReturnStatuses} status
     * @returns {Array<{label: string, value: string}>}
     * To get allowed options for editable status
     */
    getAllowanceStatusOptions(status) {
      if (!status) {
        console.error("statusOptionsMixin: status is null");
        return;
      }

      if (typeof status !== "string") {
        console.error("statusOptionsMixin: status is not a string");
        return;
      }

      const statusOptions = this.allowedOptions[status];

      if (!statusOptions) {
        // console.error(
        //   `statusOptionsMixin: status ${status} has no options allowed`
        // );
        return null;
      }

      return statusOptions;
    },
  },
};
